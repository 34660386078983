import types from './type'
export default {
    demo({ commit }, data) {
        commit(types.DEMO, data)
    },
    updateToken({ commit }, data) {
        commit(types.UPDATE_TOKEN, data)
    },
    updateUserInfo({ commit },data) {
        commit(types.UPDATE_USERINFO, data)
    },
}