import { get, post, put,deleteFn } from './requset'


/**
 * 登录
 * @param {*} params 
 * @returns 
 */
export const login = (params) => {
    return post('v1/user/access_token', params)
}
export const loginOut = () => {
    window.localStorage.setItem('LOGIN_TOKEN', '')
}
/**
 * 判断token是否过期
*/
export const tokenIsExpired = (params) => {
    return get('v1/user/access_token', params)
}
/**
 * 跳转授权页面
 */
export const tohuaweiAuth = (token) => {
    return get('v1/huawei/login.html?access_token=' + token)
}

/**
 * 判断华为是否有效
 */
export const ishuaweiAuthTrue = () => {
    return get('v1/user/current_huawei_access_token')
}
/***
 * 获取部门绩效列表
 */

export const getPerformancesDept = (params) => {
    return get('v1/user/dept_performances', {
        ...params
    })
}
/***
 * 获取个人绩效列表
 */
export const getPerformancesPersonal = (params) => {
    return get('v1/user/personal_performances', {
        ...params
    })
}

/***
 * 同步华为数据
 */
export const huaweiData = () => {
    return put('v1/huawei/user/huawei_data')
}

/***
 * 同步华为数据
 */
export const unBindAuth = () => {
    return deleteFn('v1/user/current_huawei_access_token')
}
