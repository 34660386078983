import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import axios from 'axios';
import routes from './router' // 获取路由设置
import 'amfe-flexible'
import './icons'  // 导入svg 图标文件
import './style/theme.css'  //  默认主题样式
import './style/style.css'  //  公共样式
import store from './store' // 引入store 状态管理
import debounce from './mixins/debounce';// 获取混入函数
import { Toast, Progress, ActionSheet, Checkbox, CheckboxGroup } from 'vant';
import 'vant/lib/index.less';
import { tokenIsExpired } from '@/util/api'
Vue.use(Toast);
Vue.use(Progress);
Vue.use(ActionSheet);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);

const router = new VueRouter({
  routes
})
Vue.mixin(debounce)
Vue.use(VueRouter)
/**
 * APP_BASE_API
 * 获取后台API域名
 */
const APP_BASE_API = document
  .querySelector("body")
  .getAttribute("VUE_APP_BASE_API");
if (APP_BASE_API !== null) {
  axios.defaults.baseURL = `${APP_BASE_API}`;
  Vue.prototype.$base_url = `${APP_BASE_API}`;
} else {
  Vue.prototype.$base_url = '';
  axios.defaults.baseURL = ``;
}
Vue.config.productionTip = false
/**
 * 全局路由导航
 */
router.beforeEach((to, from, next) => {
  const token = window.localStorage.getItem('LOGIN_TOKEN')
  if (to['name'] === 'LoginPage') {
    if (token) {
      tokenIsExpired({
        access_token: token
      }).then((res) => {
        if (res['code'] === 0 && res['data']['is_expired']) {
          next({ name: 'HomePage' });
        } else {
          window.localStorage.setItem('LOGIN_TOKEN', '')
          next();
        }
      })
    } else {
      next();
    }
  }
  if (to['name'] === 'HomePage') {
    if (token) {
      tokenIsExpired({
        access_token: token
      }).then((res) => {
        if (res['code'] === 0 && !res['data']['is_expired']) {
          store.dispatch('updateUserInfo', res['data'])
          next();
        } else {
          window.localStorage.setItem('LOGIN_TOKEN', '')
          next({ name: 'LoginPage' });
        }
      })
    } else {
      next({ name: 'LoginPage' });
    }
  }
})

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')

