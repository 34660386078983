

const routers = [
   {
      path: '/',
      name: 'HomePage',
      component: () => import('../view/Home')
   },
   {
      path: '/login',
      name: 'LoginPage',
      component: () => import('../view/Login')
   },
]
export default routers;