import types from './type'
export default {
    [types.DEMO](state, data) {
        state.demo = data
    },
    [types.UPDATE_TOKEN](state, data) {
        state.token = data
        window.localStorage.setItem('LOGIN_TOKEN', data)
    },
    [types.UPDATE_USERINFO](state, data) {
        state.userInfo = data
    },
}