<template>
  <div>
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>
<script>
export default {
  name: "App",
  mounted() {
    try {
      document.addEventListener('gesturestart', function (event) {
        event.preventDefault();
      });
    } catch (e) {
      console.log("🚀 ~ file: Home.vue:87 ~ activated ~ e", e)
    }

  }
};
</script>